import Vue from "vue";

const InteractiveBookService = {
	async fetchInteractiveBook(bookId, userIds) {
		// TODO: Check
		if (!bookId) bookId = "abetare";

		const response = await Vue.prototype.$axios.get(`/interactive-book`, {
			params: { book: bookId, userId: userIds },
		});

		return response.data;
	},

	async fetchInteractiveBookById(bookId) {
		const response = await Vue.prototype.$axios.get(`/interactive-book`, {
			params: { bookId },
		});

		return response.data;
	},

	async sendBookData(data) {
		try {
			let formattedData = {
				state: {
					playerState: data.state.state,
					lastPage: data.state.lastPage,
				},
				id: data.id,
			};

			console.log({ formattedData });

			await Vue.prototype.$axios.put("/interactive-book", formattedData);
		} catch (err) {
			console.log(err);
		}
	},

	getPosts() {},

	// Frame
	frame: {
		init(iFrame, iFrameId) {
			this.iFrame = iFrame;
			this.LS_KEY = iFrameId.split("-")[0];
		},

		async getCurrentPage() {
			if (!this.iFrame) throw "No iFrame found!";

			return await new Promise((res, rej) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					channel.port1.close();
					res(data.page);
				};

				this.iFrame.contentWindow.postMessage(
					{
						channel: "book",
						action: "getCurrentPage",
					},
					"*",
					[channel.port2]
				);
			});
		},

		async getPlayerState() {
			if (!this.iFrame) throw "No iFrame found!";

			return await new Promise((res, rej) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					channel.port1.close();
					res(data.state);
				};

				this.iFrame.contentWindow.postMessage(
					{
						channel: "book",
						action: "getPlayerState",
					},
					"*",
					[channel.port2]
				);
			});
		},

		loadSubset(args) {
			if (!this.iFrame) throw "No iFrame found!";
			this.iFrame.contentWindow.postMessage(
				{
					action: "loadSubset",
					params: {
						...args,
					},
				},
				"*"
			);
		},

		load(lastPage) {
			if (!this.iFrame) throw "No iFrame found!";
			this.iFrame.contentWindow.postMessage(
				{
					action: "load",
					params: {
						location: lastPage,
					},
				},
				"*"
			);
		},

		setState(state) {
			if (!this.iFrame) throw "No iFrame found!";
			this.iFrame.contentWindow.postMessage(
				{
					action: "loadState",
					params: {
						state,
					},
				},
				"*"
			);
		},
	},
};

export default InteractiveBookService;
