<template>
	<div
		v-if="state.currentClassroom"
		id="interactive-books-wrapper"
		class="overflow-y-auto"
	>
		<v-card
			style="position: sticky; top: -16px; z-index: 2"
			class="bg-card py-2 mb-4"
			outlined
		>
			<div class="text-h6 text-center">Progresi i nxënësit</div>
		</v-card>

		<v-card class="bg-card" outlined>
			<v-card-title class="d-flex justify-space-between align-center">
				<div class="text-h6">
					{{ state.currentClassroom.subject.toUpperCase() }}
				</div>
				<v-btn color="primary" depressed @click="handleBookOpen(null)">
					<v-icon left>mdi-book-open</v-icon>
					Shfaq librin
				</v-btn>
			</v-card-title>
			<v-card-text>
				<div v-if="state.bookStates.length" class="students-wrapper mt-6">
					<div
						v-for="bookState in state.bookStates"
						:key="bookState.id"
						class="d-flex justify-space-between align-center my-2"
						:class="
							$screen.width < 600
								? 'd-flex flex-column'
								: 'd-flex justify-space-between align-center my-2'
						"
						style="gap: 10px"
					>
						<div class="d-flex align-center flex-grow-1">
							<div style="width: 30%; min-width: 100px">
								{{ bookState.user.firstName + " " + bookState.user.lastName }}
							</div>
							<v-divider vertical class="mx-4" />
							<div>
								{{ bookState.user.email }}
							</div>
						</div>
						<v-btn
							color="primary"
							depressed
							outlined
							@click="handleBookOpen(bookState)"
						>
							<v-icon left>mdi-book-open</v-icon>
							Libri
						</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>

		<div v-if="state.isFetching" class="d-flex justify-center my-4">
			<v-progress-circular indeterminate color="primary" />
		</div>

		<v-card
			v-for="(exams, key) in state.examStates"
			:key="`exam-${key}`"
			class="bg-card mt-2"
			outlined
		>
			<v-card-title> {{ getExamLabel(key) }}</v-card-title>
			<v-card-text>
				<div
					v-for="exam in exams"
					:key="exam.id"
					:class="
						$screen.width < 600
							? 'd-flex flex-column'
							: 'd-flex justify-space-between align-center my-2'
					"
					style="gap: 10px"
				>
					<div class="d-flex align-center flex-grow-1">
						<div style="width: 30%; min-width: 100px">
							{{ exam.user.firstName + " " + exam.user.lastName }}
						</div>
						<v-divider vertical class="mx-4" />
						<div>
							{{ exam.user.email }}
						</div>
					</div>
					<v-btn
						color="primary"
						depressed
						outlined
						@click="handleBookOpen(exam)"
					>
						<v-icon left>mdi-book-open</v-icon>
						{{ getExamLabel(exam.exam) }}
					</v-btn>
				</div>
			</v-card-text>
		</v-card>

		<v-dialog
			v-model="state.showBook"
			scrollable
			persistent
			style="z-index: 9999"
			content-class="book-dialog"
			:fullscreen="$isMobileOnly"
		>
			<v-card
				:width="$isMobileOnly ? '100vw' : '80vw'"
				:height="$isMobileOnly ? '100vh' : '90vh'"
			>
				<v-card-title class="d-flex align-center flex-nowrap">
					{{ getDialogHeader(state.currentBook) }}
					<v-spacer />
					<v-btn
						v-if="state.currentBook"
						@click="handleBookSave"
						:loading="state.isSaving"
						icon
						color="primary"
						class="mx-2"
					>
						<v-icon>mdi-check</v-icon>
					</v-btn>
					<v-btn icon @click="handleBookClose">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0 overflow-hidden d-flex align-center">
					<div id="book-wrapper" :style="state.wrapperStyles">
						<div v-show="state.showLoader" class="book-loader">
							<v-progress-circular
								indeterminate
								color="primary"
								size="60"
								width="7"
								style="
									position: absolute;
									left: 50%;
									top: 50%;
									transform: translate(-50%, -50%);
								"
							/>
						</div>

						<iframe
							v-if="state.showBook"
							:src="state.iFrameSrc"
							:id="state.iFrameId"
							:style="state.iFrameStyles"
						/>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";
import InteractiveBookService from "../services/interactiveBook.service";
import ClassroomService from "../services/classroom.service";

import Vue from "vue";
import { groupByKey } from "../../../shared/utilities/general.utilities";
import FlashService from "../services/flash.service";

export default {
	name: "InteractiveBooks",
	props: {
		classroomId: {
			type: String,
			required: true,
		},
	},
	setup(props, { root }) {
		const state = reactive({
			// Main book
			bookStates: [],
			// Exams
			examStates: [],

			currentBook: null,

			showBook: false,
			isBookReady: false,
			isPageLoaded: false,
			showLoader: true,

			iFrameSrc: "",
			iFrameId: "book-iframe",
			iFrameScale: 1,
			iFrameDimensions: null,
			wrapperDimensions: null,
			wrapperStyles: computed(() => getWrapperStyles(state.wrapperDimensions)),

			iFrameStyles: computed(() =>
				getIFrameStyles(state.iFrameDimensions, state.iFrameScale)
			),

			currentClassroom: computed(() =>
				ClassroomService.getClassroomById(props.classroomId)
			),

			students: computed(() =>
				ClassroomService.getClassroomStudents(props.classroomId)
			),

			isFetching: false,
			isSaving: false,
		});

		// TODO: Fetch state when needed
		watch(
			() => state.students,
			async (students) => {
				// console.log({ students });
				try {
					state.isFetching = true;
					const allStates = await InteractiveBookService.fetchInteractiveBook(
						"abetare",
						students
					);

					state.bookStates = allStates.filter((book) => !book.exam);
					state.examStates = groupByKey(
						allStates.filter((book) => book.exam),
						"exam",
						{ omitKey: false }
					);

					// console.log({
					// 	exams: groupByKey(state.examStates, "exam", { omitKey: false }),
					// });
				} catch (err) {
					console.log(err);
				} finally {
					state.isFetching = false;
				}
			},
			{ immediate: true }
		);

		watch(
			() => [state.isBookReady, state.isPageLoaded],
			async ([bookIsReady, pageIsLoaded]) => {
				if (pageIsLoaded) {
					console.log("PAGE IS LOADED");
					window.addEventListener("resize", scaleIFrameWrapper);

					await setIFrameInitialSize();

					setTimeout(() => {
						state.showLoader = false;
					}, 50);
				} else if (bookIsReady) {
					console.log("BOOK IS READY");

					const iFrame = document.getElementById(state.iFrameId);
					InteractiveBookService.frame.init(iFrame, state.iFrameId);

					// TODO: Not used - Fix

					if (Array.isArray(props.subset) && props.subset?.length) {
						InteractiveBookService.frame.loadSubset(
							state.currentBook.state.lastPage
						);
					} else {
						let loadPage = 0;
						if (state.currentBook?.exam) {
							loadPage = 0;
						} else if (state.currentBook) {
							loadPage = state.currentBook.state.lastPage;
						} else {
							loadPage = 0;
						}

						InteractiveBookService.frame.load(loadPage);
					}
					if (state.currentBook) {
						InteractiveBookService.frame.setState(
							state.currentBook.state.playerState
						);
					}
				}
			},
			{ immediate: true }
		);

		function handleIFrameMsg(e) {
			if (!e.data) return;
			const data = e.data;

			const { action, params } = data;

			switch (action) {
				case "ready":
					state.isBookReady = true;
					break;
				case "pageLoaded":
					state.isPageLoaded = true;
					break;
			}
		}

		async function setIFrameInitialSize() {
			const iFrame = document.getElementById(state.iFrameId);

			const icPlayer = await new Promise((res, rej) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					channel.port1.close();
					res(data.dimensions);
				};

				iFrame.contentWindow.postMessage(
					{
						channel: "book",
						action: "getDimensions",
					},
					"*",
					[channel.port2]
				);
			});

			Vue.set(state, "iFrameDimensions", {
				width: icPlayer.width,
				height: icPlayer.height,
			});
			Vue.set(state, "wrapperDimensions", {
				width: icPlayer.width,
				height: icPlayer.height,
				initWidth: icPlayer.width,
				initHeight: icPlayer.height,
			});
			scaleIFrameWrapper();
		}

		function scaleIFrameWrapper() {
			const wrapper = document.getElementById("book-wrapper")?.parentElement;

			if (!wrapper) return;

			const wrapperHeight = wrapper?.offsetHeight;
			const wrapperWidth = wrapper?.offsetWidth;

			state.iFrameScale = Math.min(
				wrapperWidth / state.iFrameDimensions.width,
				wrapperHeight / state.iFrameDimensions.height
			);

			state.wrapperDimensions.height =
				state.wrapperDimensions.initHeight * state.iFrameScale;
			state.wrapperDimensions.width =
				state.wrapperDimensions.initWidth * state.iFrameScale;
		}

		function getIFrameStyles(dimensions, scale) {
			if (!dimensions) return "";
			const { width, height } = dimensions;
			if (width == null || height == null) return "";

			const styles = [
				`width: ${width}px`,
				`height: ${height}px`,
				"margin-left: auto",
				"margin-right: auto",
				"transform-origin: left top",
				"position: relative",
				"top: 0px",
				`transform: scale(${scale})`,
			];

			return styles.join(";");
		}

		function getWrapperStyles(dimensions) {
			if (!dimensions) return "";
			const { width, height } = dimensions;
			if (width == null || height == null) return "";
			const styles = [
				"margin: auto",
				// `margin-top: calc(50vh - ${height / 2}px)`,
				`width: ${width}px`,
				`height: ${height}px`,
			];
			return styles.join(";");
		}

		const handleBookOpen = async (book) => {
			console.log({ book });

			if (book) {
				try {
					state.currentBook =
						await InteractiveBookService.fetchInteractiveBookById(book.id);
				} catch (err) {
					console.log(err);
					state.currentBook = book;
				}
			}

			state.iFrameSrc = getIFrameSrc(state.currentBook);
			state.showBook = true;
			window.addEventListener("message", handleIFrameMsg);
		};

		const handleBookClose = () => {
			state.showBook = false;
			state.currentBook = null;
			state.isBookReady = false;
			state.isPageLoaded = false;
			state.showLoader = true;

			window.removeEventListener("resize", scaleIFrameWrapper);
		};

		function getIFrameSrc(book) {
			if (!state.currentClassroom) return;
			let path = `${process.env.VUE_APP_BASE_URL}/books/${state.currentClassroom.subject}`;

			if (book?.exam) {
				path += `/test-${book.exam}`;
			} else {
				path += `/main`;
			}

			return path + `/index.html`;
		}

		const getDialogHeader = (book) => {
			let header = "";
			let user;

			if (book) {
				user = `${book.user.firstName} ${book.user.lastName}`;
			}

			if (book?.exam) {
				header = `${getExamLabel(book.exam)} | ${user}`;
			} else if (book) {
				header = `Libri i ${user}`;
			} else {
				header = `Libri`;
			}

			return header;
		};

		function getExamLabel(key) {
			if (key === "yearly") {
				return `Testim Vjetor`;
			}

			return `Testim ${key}`;
		}

		async function handleBookSave() {
			try {
				if (!state.currentBook) return;

				state.isSaving = true;
				const regex = /.*IWB_Toolbar1/g;

				const initialPlayerState = state.currentBook.state.playerState;

				const parseState = (str) => JSON.parse(JSON.parse(str).state);
				const jsonInitState = parseState(initialPlayerState);

				let newState = {
					lastPage: await InteractiveBookService.frame.getCurrentPage(),
					playerState: await InteractiveBookService.frame.getPlayerState(),
				};

				const newParsedState = parseState(newState.playerState);

				for (let key in jsonInitState) {
					if (key.match(regex)) {
						jsonInitState[key] = newParsedState[key];
					}
				}

				let formattedState = JSON.parse(initialPlayerState);
				formattedState.state = JSON.stringify(jsonInitState);
				formattedState = JSON.stringify(formattedState);

				await InteractiveBookService.sendBookData({
					state: {
						state: formattedState,
						lastPage: newState.lastPage,
					},
					id: state.currentBook.id,
				});

				FlashService.push({
					type: "success",
					msg: "Ruajtja u krye me sukses!",
				});
			} catch (err) {
				FlashService.push({
					type: "error",
					msg: "Ka ndodhur një gabim!",
				});

				console.log(err);
			} finally {
				state.isSaving = false;
			}
		}

		return {
			state,

			handleBookOpen,
			handleBookClose,
			getDialogHeader,

			getExamLabel,

			handleBookSave,
		};
	},
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
	.book-dialog {
		width: fit-content !important;
	}

	.book-loader {
		width: 100%;
		height: 100%;
		background: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999;
	}
}

#book-iframe {
	width: 100%;
	border: none !important;
}
</style>
